import React, { useState, useEffect } from "react";
import { CircularProgress, Box } from "@mui/material";

const ServerStatusChart = ({ ip, port }) => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetch(`https://vieratech.ai/projects/Salient-Dashboard-PHP/rest/servers.php/server/serverStatus?IP=${ip}&port=${port}`);
        
        // Check if response is successful (status 200)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        setStatus(data.status); // 1 for up, 0 for down
      } catch (error) {
        console.error("Error fetching server status:", error);
      }
    };

    fetchStatus();
  }, [ip]);

  if (status === null) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        height: "20px",
        backgroundColor: status === 1 ? "green" : "red",
        animation: status === 1 ? "pulse 1s infinite" : "none",
      }}
    >
      {status === 0 && <span style={{ color: "white" }}>Down</span>}
    </Box>
  );
};

export default ServerStatusChart;
