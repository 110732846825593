import { TextField, Button, FormControlLabel, Checkbox } from "@mui/material";
import { set } from "lodash";
import { useState } from "react";

const SitesEditForm = ({ initialData, onEdit }) => {
  const [siteName, setSiteName] = useState(initialData.siteName);
  const [serverName, setServerName] = useState(initialData.serverName);
  const [isInstalled, setIsInstalled] = useState(initialData.isInstalled === "Installed" ? true : false);
  const [siteId, setSiteId] = useState(initialData.id);

  const handleEdit = () => {
    let isintallEdit = isInstalled ? "Installed" : "No";
    onEdit({ siteId, siteName, serverName, isintallEdit });
  };

  return (
    <div>
      <TextField
        label="Site Name"
        value={siteName}
        onChange={(e) => setSiteName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Server Name"
        value={serverName}
        onChange={(e) => setServerName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isInstalled}
            onChange={(e) => setIsInstalled(e.target.checked)}
            color="primary"
          />
        }
        label="Is Installed"
      />
      <Button onClick={handleEdit} variant="contained" color="primary" fullWidth>
        Edit
      </Button>
    </div>
  );
};

export default SitesEditForm;
