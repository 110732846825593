import * as React from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
import { set, split } from 'lodash';
import UsersEditForm from './UsersEditForm';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const StyledTableCell = styled(TableCell)(({ theme }) => ({
[`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[600],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const UsersList = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const[displayName, setDisplayName] = useState(null);
  const [message, setMessage] = useState("");
  const [editUserPopUp, setEditUserPopUp] = useState("");

  const handleClickOpen = (id, displayName) => {
    setOpen(true);
    setUserId(id);
    setDisplayName(displayName);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setUserId(null);
  };

  const showEditUserPopUp = async (userData) => {
    const response = await fetch(
      `https://vieratech.ai/projects/Salient-Dashboard-PHP/rest/groups.php/group/getGroupsByUserId?userId=`+ userData.id +`&timestamp=${new Date().getTime()}`
  );

  let userGroups = await response.json();
  
  userData.admin = userGroups[0].role;
  userData.userGroups = split(userGroups[0].userGroups, ',');
  
    setEditUserPopUp(
      <div>
        <UsersEditForm
          userData={userData}
          onCalcelEditExp={hideEditUserPopUp}
          // addUserListHandler={addUserListHandler}
        />
      </div>
    );
  };

  const hideEditUserPopUp = () => {
    setEditUserPopUp("");
  }

  const handleClose = async () => {
    setOpen(false);
    setUserId(null);
    const userData = {
      id: userId
    }

    try {
      await fetch("https://vieratech.ai/projects/Salient-Dashboard-PHP/rest/user.php/user/deleteUser", {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      }).then(
        (response) => {
          if (response.status === 200) {
            props.getUsers();
            setMessage(
              <Alert severity="success">{t("User-deleted successfully")}</Alert>
            );
            setTimeout(function () {
              setMessage("");
            }, 5000);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

    return(
        <div class='w-full px-16'>
            {message}
            <div class="my-8">{editUserPopUp}</div>
            <Typography class='text-xs font-bold mt-4 text-left'>{t('User List')} - Total: {props.usersCount}</Typography>
            <TableContainer component={Paper} sx={{borderRadius: '10px', marginTop: '20px'}}>
            <Table aria-label="customized table" class='w-full'>
                <TableHead>
                <TableRow>
                    <StyledTableCell>{t('Name')}</StyledTableCell>
                    <StyledTableCell align="right">{t('Email')}</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                    <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {props.filteredUsers.map((row) => (
                    <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                        {row.displayName}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.email}</StyledTableCell>
                    <StyledTableCell align="right">
                      <DeleteIcon
                        onClick={() => handleClickOpen(row.id, row.displayName)}
                        style={{cursor: 'pointer'}}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <EditIcon 
                        style={{cursor: 'pointer'}}
                        onClick={() => showEditUserPopUp(row)}
                      />
                    </StyledTableCell>
                    </StyledTableRow>
                    
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
              sx={{minWidth: '300px'}}
            >
              <DialogTitle>{displayName}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Confirm delete user {displayName}?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button onClick={handleClose}>Delete</Button>
              </DialogActions>
      </Dialog>
        </div>
    );
}

export default UsersList;