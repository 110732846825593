import classes from "./Sites.module.css";
import { useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import Navigation from "../navigation/Navigation";
import ProfileBar from "../profileBar/ProfileBar";
import SitesHeader from "./widgets/SitesHeader";
import SitesList from "./widgets/SitesList";
import SitesAddForm from "./widgets/SitesAddForm";
import { useTranslation } from "react-i18next";

const Sites = () => {
  const [sitesList, setSitesList] = useState([]);
  const [totalSites, setTotalSites] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [editingSite, setEditingSite] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchSites();
  }, []);

  const fetchSites = async () => {
    const response = await fetch(
      `https://vieratech.ai/projects/Salient-Dashboard-PHP/api/get-sites.php?timestamp=${new Date().getTime()}`
    );
    const sites = await response.json();
    setSitesList(sites);
    setTotalSites(sites.length);
  };

  const handleSave = async (siteData) => {
    // Send POST request to the API
    await fetch(
      "https://vieratech.ai/projects/Salient-Dashboard-PHP/api/create-site.php",
      {
        method: "POST",
        body: JSON.stringify(siteData),
      }).then((response) => {
      if (!response.ok) {
        throw new Error("Failed to create site");
        }
      });


    await fetchSites();
    setShowForm(false);
    setEditingSite(null);
  };

  const handleShowForm = () => {
    setShowForm(!showForm);
    setEditingSite(null);
  };

  const handleEdit = async(site) => {
    // setEditingSite(site);
    // setShowForm(true);
    await fetch(
      `https://vieratech.ai/projects/Salient-Dashboard-PHP/api/edit-site.php`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ siteId: site.siteId, siteName: site.siteName, serverName: site.serverName, isInstalled: site.isintallEdit }),
      }
    );
    await fetchSites();
  };

  const handleDelete = async (siteId) => {
    if (window.confirm(t("Are you sure you want to delete this site?"))) {
      await fetch(
        `https://vieratech.ai/projects/Salient-Dashboard-PHP/api/delete-site.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ siteId: siteId }),
        }
      );
      await fetchSites();
    }
  };

  return (
    <div className={classes.dashboardCenter}>
      <Stack direction="row" className="flex justify-evenly">
        <Grid item xs={2} className="bgSaltexColor">
          <Navigation />
        </Grid>
        <Grid container item xs={10} className="bgPage">
          <div className={classes.divCenterDash}>
            <ProfileBar />
            <SitesHeader />
            <div className="bg-white rounded-md m-7 p-7">
              {showForm ? (
                <SitesAddForm
                  onShowForm={handleShowForm}
                  onSave={handleSave}
                  editingSite={editingSite}
                  onEdit={handleEdit}
                />
              ) : (
                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleShowForm}
                >
                  {t("Add New Site")}
                </Button>
              )}
              <SitesList
                filteredGroups={sitesList}
                serversCount={totalSites}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            </div>
          </div>
        </Grid>
      </Stack>
    </div>
  );
};

export default Sites;
