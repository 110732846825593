import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SitesEditForm from "./SitesEditForm"; // Assuming SitesEditForm is in the same directory

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${TableCell.head}`]: {
    backgroundColor: grey[600],
    color: theme.palette.common.white,
  },
  [`&.${TableCell.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SitesList = ({ serversCount, filteredGroups, onEdit, onDelete }) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingSite, setEditingSite] = useState(null);

  const handleEdit = (site) => {
    setEditingSite(site);
    setIsDialogOpen(true); // Open the dialog
  };

  const handleSave = (updatedSiteData) => {
    // Save the updated data
    onEdit(updatedSiteData); // You can replace this with a state update if required
    setIsDialogOpen(false); // Close the dialog
    setEditingSite(null); // Clear the editing site
  };

  return (
    <div style={{ width: "100%", padding: "0 16px" }}>
      <Typography
        variant="subtitle1"
        style={{ fontWeight: "bold", marginTop: "16px", textAlign: "left" }}
      >
        {t("Sites List")} - {t("Total")}: {serversCount}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: "10px", marginTop: "20px" }}
      >
        <Table aria-label="sites table" sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("Site Name")}</StyledTableCell>
              <StyledTableCell align="right">{t("Server Name")}</StyledTableCell>
              <StyledTableCell align="right">{t("Is Installed")}</StyledTableCell>
              <StyledTableCell align="right">{t("Actions")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredGroups.map((site) => (
              <StyledTableRow key={site.id}>
                <StyledTableCell component="th" scope="row">
                  {site.siteName}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {site.serverName}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {site.isInstalled}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => handleEdit(site)}
                  >
                    {t("Edit")}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => onDelete(site.id)}
                    style={{ marginLeft: "8px" }}
                  >
                    {t("Delete")}
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Dialog */}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>{editingSite ? editingSite.siteName : t("Edit Site")}</DialogTitle>
        <DialogContent>
          <SitesEditForm
            initialData={{
              siteName: editingSite ? editingSite.siteName : "",
              serverName: editingSite ? editingSite.serverName : "",
              isInstalled: editingSite ? editingSite.isInstalled : "No",
              id: editingSite ? editingSite.id : "",
            }}
            onEdit={handleSave}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="primary">
            {t("CAncel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SitesList;
