import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
  Alert,
} from "@mui/material";
import { CheckCircle, Error } from "@mui/icons-material"; // Optional: For icons
import { useTranslation } from "react-i18next";

const SiteAddForm = ({ isOpen=true, onShowForm, onSave }) => {
  const { t } = useTranslation();
  const [siteName, setSiteName] = useState("");
  const [serverName, setServerName] = useState("");
  const [isInstalled, setIsInstalled] = useState("No");
  const [isLoading, setIsLoading] = useState(false); // To show loading spinner
  const [message, setMessage] = useState(""); // To show success/error message

  const handleSiteNameChange = (e) => setSiteName(e.target.value);
  const handleServerNameChange = (e) => setServerName(e.target.value);
  const handleIsInstalledChange = (e) => setIsInstalled(e.target.value);

  const handleSave = () => {
    setIsLoading(true); // Start loading
    const newSite = {
      siteName: siteName,
      serverName: serverName,
      isInstalled: isInstalled,
    };

    setTimeout(() => {
      onSave(newSite);
      setIsLoading(false); // Stop loading
    }, 2000); // Simulate an API call
   
    setMessage(
      <Alert severity="success">{t("Site created successfully")}</Alert>
    );
    setTimeout(function () {
      setMessage("");
    }, 5000);
  };

  return (
    <Dialog open={isOpen} onClose={onShowForm} maxWidth="sm" fullWidth>
      {message}
      <DialogTitle>{t(siteName ? siteName : "Add New Site")}</DialogTitle>
      <DialogContent>
        <form>
          {/* Site Name Field */}
          <TextField
            label={t("Site Name")}
            variant="outlined"
            fullWidth
            value={siteName}
            onChange={handleSiteNameChange}
            margin="normal"
            required
          />

          {/* Server Name Field */}
          <TextField
            label={t("Server Name")}
            variant="outlined"
            fullWidth
            value={serverName}
            onChange={handleServerNameChange}
            margin="normal"
            required
          />

          {/* Is Installed Field */}
          <FormControl fullWidth margin="normal" required>
            <InputLabel>{t("Is Installed")}</InputLabel>
            <Select
              value={isInstalled}
              onChange={handleIsInstalledChange}
              label={t("Is Installed")}
              fullWidth
            >
              <MenuItem value="No">{t("No")}</MenuItem>
              <MenuItem value="Installed">{t("Installed")}</MenuItem>
            </Select>
            <FormHelperText>{t("Select installation status")}</FormHelperText>
          </FormControl>
        </form>
      </DialogContent>

      {/* Action Buttons */}
      <DialogActions>
        <Button onClick={onShowForm} color="secondary" disabled={isLoading}>
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={isLoading || !siteName || !serverName} // Disable button if fields are empty
        >
          {isLoading ? <CircularProgress size={24} color="secondary" /> : t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SiteAddForm;
