/* eslint-disable no-extend-native */
import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
// import jwtService from '../../../auth/services/jwtService';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Base64 from "crypto-js/enc-base64";
import HmacSHA256 from "crypto-js/hmac-sha256";
import Utf8 from "crypto-js/enc-utf8";
import FaceIcon from "@mui/icons-material/Face";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PasswordIcon from "@mui/icons-material/Password";
import { Alert } from "@mui/material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { useTranslation } from "react-i18next";
import { method } from "lodash";

let groupList = [];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const jwtSecret = "marlon-cruces-senior-dev";

function base64url(source) {
  // Encode in classical base64
  let encodedSource = Base64.stringify(source);

  // Remove padding equal characters
  encodedSource = encodedSource.replace(/=+$/, "");

  // Replace characters according to base64url specifications
  encodedSource = encodedSource.replace(/\+/g, "-");
  encodedSource = encodedSource.replace(/\//g, "_");

  // Return the base64 encoded string
  return encodedSource;
}

function generateJWTToken(tokenPayload) {
  // Define token header
  const header = {
    alg: "HS256",
    typ: "JWT",
  };

  // Calculate the issued at and expiration dates
  const date = new Date();
  const iat = Math.floor(date.getTime() / 1000);
  const exp = Math.floor(date.setDate(date.getDate() + 7) / 1000);

  // Define token payload
  const payload = {
    iat,
    iss: "Fuse",
    exp,
    ...tokenPayload,
  };

  // Stringify and encode the header
  const stringifiedHeader = Utf8.parse(JSON.stringify(header));
  const encodedHeader = base64url(stringifiedHeader);

  // Stringify and encode the payload
  const stringifiedPayload = Utf8.parse(JSON.stringify(payload));
  const encodedPayload = base64url(stringifiedPayload);

  // Sign the encoded header and mock-api
  let signature = `${encodedHeader}.${encodedPayload}`;
  signature = HmacSHA256(signature, jwtSecret);
  signature = base64url(signature);

  // Build and return the token
  // return `${encodedHeader}.${encodedPayload}.${signature}`;
  return `${signature}`;
}

const UsersEditForm = (props) => {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState([true, true]);
  const [checkedEdit, setCheckedEdit] = React.useState(true);
  const [adminChecked, setAdminChecked] = React.useState([true, true]);
  const [adminCheckedEdit, setAdminCheckedEdit] = React.useState(false);
  const [userName, setUserName] = React.useState(props.userData.displayName);
  const [userEmail, setUserEmail] = React.useState(props.userData.email);
  const [userPasswd1, setUserPasswd1] = React.useState("");
  const [userPasswd2, setUserPasswd2] = React.useState("");
  const [userGroups, setUserGroups] = React.useState("");
  const [userGroupsVal, setUserGroupsVal] = React.useState(props.userData.userGroups); 
  const [userAdmin, setUserAdmin] = React.useState("staff");
  const [message, setMessage] = React.useState("");
  const [messagePasswd, setMessagePasswd] = React.useState("");
  // const [open, setOpen] = React.useState(false);
  const [groupsVal, setGroupsVal] = React.useState([]);
  const [groupListEdit, setGroupListEdit] = React.useState([]);
  
  const getGroups = async () => {
    const response = await fetch(
      `https://vieratech.ai/projects/Salient-Dashboard-PHP/rest/groups.php/group/listGroup?timestamp=${new Date().getTime()}`
    );

    const data = await response.json();

    let groupListAll = [];
    data.map((row) => {
        let groupChecked = userGroupsVal.includes(row.groupId.toString()) ? true : false;
        groupListAll.push(
            {
                groupId: row.groupId,
                groupName: row.groupName,
                groupEmail: row.groupEmail,
                groupServers: row.groupServers,
                groupChecked: groupChecked,
            }
        );

        if (groupChecked) {
            groupList.push(row.groupId.toString());
        }
    });

    setGroupsVal(groupListAll);
  };

  useEffect(() => {
    getGroups();
    if (props.userData.admin === "admin") {
        setAdminCheckedEdit(true);
    }

  }, []);

  const handleSubmitUser = async (e) => {
    e.preventDefault();

    if (userPasswd1.length > 0 || userPasswd2.length > 0) {
        if (userPasswd1 !== userPasswd2) {
          setMessagePasswd(
            <Alert severity="error">{t("Passwords should be the same")}</Alert>
          );
          setTimeout(function () {
            setMessagePasswd("");
          }, 5000);
          setUserPasswd1("");
          setUserPasswd2("");
        } else {
          const UserT = "generateGUID()";
          const accessToken = generateJWTToken({ id: UserT });
          if(userGroups.length === 0 ) userGroups.push(0);
          const userData = {
            userNameVal: userName,
            userEmailVal: userEmail,
            userPasswdVal: userPasswd1,
            userGroupsVal: userGroups.toString(),
            userAdmin: userAdmin,
            id: props.userData.id,
          };
          console.log(JSON.stringify({ userData }));
    
          try {
            await fetch(
              "https://vieratech.ai/projects/Salient-Dashboard-PHP/rest/user.php/user/editUser",
              {
                method: "POST",
                body: JSON.stringify(userData),
              }
            ).then((result) => {
              if (result.status === 200) {
                setUserName("");
                setUserEmail("");
                setUserPasswd1("");
                setUserPasswd2("");
                setMessage(t("User-updated successfully"));
                // setOpen(true);
                setChecked([true, false]);
                setGroupsVal([]);
                getGroups();
                // props.addUserListHandler(userData);
                setMessage(
                  <Alert severity="success">{t("User-updated successfully")}</Alert>
                );
                setTimeout(function () {
                  setMessage("");
                  window.location.reload();
                }, 2000);
              } else {
                setMessage(
                  <Alert severity="error">
                    {t("Some error occurred, Contact Tesch Support")}
                  </Alert>
                );
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
    } else {
        const UserT = "generateGUID()";
          const accessToken = generateJWTToken({ id: UserT });
          if(userGroups.length === 0 ) userGroups.push(0);
          const userData = {
            userNameVal: userName,
            userEmailVal: userEmail,
            userGroupsVal: userGroups.toString(),
            userAdmin: userAdmin,
            id: props.userData.id,
          };
          console.log(JSON.stringify({ userData }));
    
          try {
            await fetch(
              "https://vieratech.ai/projects/Salient-Dashboard-PHP/rest/user.php/user/editUser",
              {
                method: "PUT",
                body: JSON.stringify(userData),
              }
            ).then((result) => {
              if (result.status === 200) {
                setUserName("");
                setUserEmail("");
                setUserPasswd1("");
                setUserPasswd2("");
                setMessage(t("User-updated successfully"));
                // setOpen(true);
                setChecked([true, false]);
                setGroupsVal([]);
                getGroups();
                // props.addUserListHandler(userData);
                setMessage(
                  <Alert severity="success">{t("User-updated successfully")}</Alert>
                );
                setTimeout(function () {
                  setMessage("");
                  window.location.reload();
                }, 2000);
              } else {
                setMessage(
                  <Alert severity="error">
                    {t("Some error occurred, Contact Tesch Support")}
                  </Alert>
                );
              }
            });
          } catch (err) {
            console.log(err);
          }
    }
  };

  const handleUserName = (event) => {
    const userNameText = event.target.value;
    /// console.log(userNameText);
    setUserName(userNameText);
  };

  const handleUserEmail = (event) => {
    const groupNameText = event.target.value;
    // console.log(groupNameText);
    setUserEmail(groupNameText);
  };

  const handleUserPasswd1 = (event) => {
    const groupNameText = event.target.value;
    // console.log(groupNameText);
    setUserPasswd1(groupNameText);
  };

  const handleUserPasswd2 = (event) => {
    const groupNameText = event.target.value;
    // console.log(groupNameText);
    setUserPasswd2(groupNameText);
  };

  Array.prototype.remove = function () {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L && this.length) {
      what = a[--L];
      while ((ax = this.indexOf(what)) !== -1) {
        this.splice(ax, 1);
      }
    }
    return this;
  };

  const handleChange3 = (event) => {
      setChecked([checked[0], event.target.checked]);
      setCheckedEdit(false);
    if (groupList.includes(event.target.id)) {
      groupList.remove(event.target.id);
    } else {
      groupList.push(event.target.id);
    }
    setUserGroups(groupList);
  };

  const handleChangeAdmin = (event) => {
    setAdminChecked([checked[0], event.target.checked]);
    setAdminCheckedEdit(event.target.checked);
    if (adminChecked[1]) {
        setUserAdmin("admin");
        groupList = [];
        setUserGroups(groupList);
    } 
    else setUserAdmin("staff");
    // console.log(userAdmin);
  };

  const childrenGroups = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      {
        groupsVal.map((row) => (
            <FormControlLabel
                key={row.groupId}
                label={row.groupName}
                control={
                    <Checkbox
                        label={row.groupName}
                        id={row.groupId}
                        value= {checked}
                        onChange={handleChange3}
                        {...(row.groupChecked ? {checked: true} : { })}
                    />
                }
            />
     ))}
    
    </Box>
  );

  const hiddeExpPopUp = () => {
    props.onCalcelEditExp();

  };

  return (
    <Box sx={{ width: "97%" }}>
      {message} {messagePasswd}
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        spacing={2}
      >
        <Grid item xs={6}>
          <Item>
            <form onSubmit={handleSubmitUser}>
              <Typography className="text-lg font-bold leading-none">
                {t("Edit Users")}
              </Typography>
              <div className="flex flex-col max-w-full p-24 pt-32 sm:pt-40 sm:p-32 w-480">
                <div className="flex sm:space-x-24 mb-16">
                  <FaceIcon />
                  <TextField
                    id="userName"
                    label={t("Display Name")}
                    className="flex-auto"
                    onChange={(e) => handleUserName(e)}
                    value={userName}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    required
                    fullWidth
                  />
                </div>
                <div className="flex sm:space-x-24 mb-16">
                  <AlternateEmailIcon />
                  <TextField
                    id="userEmail"
                    label={t("Email")}
                    className="flex-auto"
                    onChange={(e) => handleUserEmail(e)}
                    value={userEmail}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    required
                    fullWidth
                  />
                </div>
                <div className="flex sm:space-x-24 mb-16">
                  <PasswordIcon />
                  <TextField
                    id="userPasswd1"
                    label={t("Password")}
                    className="flex-auto"
                    type="password"
                    onChange={(e) => handleUserPasswd1(e)}
                    value={userPasswd1}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="flex sm:space-x-24 mb-16">
                  <PasswordIcon />
                  <TextField
                    id="userPasswd2"
                    label={t("Confirm Password")}
                    className="flex-auto"
                    type="password"
                    onChange={(e) => handleUserPasswd2(e)}
                    value={userPasswd2}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="flex sm:space-x-24 mb-16">
                  <AdminPanelSettingsOutlinedIcon />
                  <FormControlLabel
                    key="354A"
                    label="Is admin"
                    control={
                      <Checkbox
                        label="Is Admin"
                        id="354A"
                        value={adminChecked}
                        onChange={handleChangeAdmin}
                        checked={adminCheckedEdit}
                      />
                    }
                  />
                </div>
                <div className="flex sm:space-x-24 mb-16">
                  <Button
                    variant="outlined"
                    color="error"
                    className=" w-1/2 mt-16"
                    aria-label="Add"
                    type="button"
                    onClick={hiddeExpPopUp}
                    size="large"
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    variant="outlined"
                    color="success"
                    className=" w-1/2 mt-16"
                    aria-label="Add"
                    type="submit"
                    size="large"
                  >
                    {t("Edit User")}
                  </Button>
                </div>
              </div>
            </form>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Typography className="text-lg font-bold leading-none">
            {t("Groups")}
          </Typography>
          <Typography className="text-lg leading-none">
            {t("Select the Groups for the new user")}
          </Typography>
          <Typography className="text-lg leading-none text-blue-500">
            {t("Do not select any group to get access to all groups")}
          </Typography>
          <div class="rounded-md bg-grey-100 mt-28 p-20">{childrenGroups}</div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UsersEditForm;
